<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="descricao"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="v-descricao">
                <b-form-input
                  id="v-descricao"
                  v-model="form.descricao"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Descrição"
                  data-cy="description"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-description" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="plano de conta"
              rules="required"
            >
              <b-form-group label="Plano de Conta" label-for="v-plano-conta">
                <v-select
                  v-model="form.planoDeConta.id"
                  :reduce="(comboPlanoConta) => comboPlanoConta.id"
                  label="classe"
                  :options="comboPlanoConta"
                  :state="errors.length > 0 ? false : null"
                  data-cy="plano-conta"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-plano-conta" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_FINAN_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_FINAN_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('typeOfPaymentReceiptSettingsModule', {
      typeOfPaymentReceipt: (state) => state.typeOfPaymentReceipt,
      comboPlanoConta: (state) => state.comboPlanoConta
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Tipos de Pagamento / Recebimento',
      required,
      form: {
        descricao: '',
        planoDeConta: {
          id: null
        },
        ativo: true
      }
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.typeOfPaymentReceipt))
    this.$store.dispatch(
      'typeOfPaymentReceiptSettingsModule/loadComboPlanoContas'
    )
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch(
        'typeOfPaymentReceiptSettingsModule/resetTypeOfPaymentReceipt'
      )
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.form.id) {
            this.$store
              .dispatch(
                'typeOfPaymentReceiptSettingsModule/editTypeOfPaymentReceipt',
                this.form
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.$store
              .dispatch(
                'typeOfPaymentReceiptSettingsModule/insertNewTypeOfPaymentReceipt',
                this.form
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cleanObjeto() {
      this.form = {
        descricao: '',
        planoDeConta: {
          id: null
        },
        ativo: true
      }
    }
  }
}
</script>
