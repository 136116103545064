var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-descricao"}},[_c('b-form-input',{attrs:{"id":"v-descricao","state":errors.length > 0 ? false : null,"placeholder":"Descrição","data-cy":"description","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"plano de conta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Plano de Conta","label-for":"v-plano-conta"}},[_c('v-select',{attrs:{"reduce":function (comboPlanoConta) { return comboPlanoConta.id; },"label":"classe","options":_vm.comboPlanoConta,"state":errors.length > 0 ? false : null,"data-cy":"plano-conta","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.planoDeConta.id),callback:function ($$v) {_vm.$set(_vm.form.planoDeConta, "id", $$v)},expression:"form.planoDeConta.id"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-plano-conta"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_FINAN_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }